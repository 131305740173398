import en from "./en";
import ne from "./ne";

export default {
  en: {
    ...en
  },
  ne: {
    ...ne
  }
};
