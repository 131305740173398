export default {
  "topnav.web": "वेब मेल",
  "topnav.low": "लो ब्यान्डविड्थ",
  "topnav.screen": "स्क्रीन रीडर",
  "topnav.oldsite": "पुरानो साईट",
  "logo.first": "नेपाल सरकार",
  "logo.second": "सञ्‍चार तथा सूचना प्रविधि मन्त्रालय",
  "logo.third": "जिल्ला हुलाक कार्यालय दाङ ",

  //   Bottom Navbar
  "bottomnav.home": "गृहपृष्ठ",
  "bottomnav.about": "परिचय ",
  "bottomnav.department": "हाम्रो बारेमा",
  "bottomnav.underneath": "कार्यालय अन्तर्गतका निकायहरू",
  "bottomnav.staff": "कर्मचारी विवरण",
  "bottomnav.citizen": "नागरिक बडापत्र",
  "bottomnav.policy": "नीति तथा कार्यक्रम",
  "bottomnav.acts": "ऐन तथा नियमावली",
  "bottomnav.media": "मिडिया सेन्टर",
  "bottomnav.notice": "सूचना",
  "bottomnav.righttoinformation": "सूचनाको हक",
  "bottomnav.press": "प्रेश विज्ञप्ति",
  "bottomnav.news": "समाचार",
  "bottomnav.tender": "बोलपत्र",
  "bottomnav.circular": "परिपत्र",
  "bottomnav.activities": "क्रियाकलापहरू",
  "bottomnav.postal": "हुलाक दर",
  "bottomnav.office": "कार्यालयहरु ",
  "bottomnav.area_office": "इलाका कार्यालयहरु",
  "bottomnav.additional_office": "अतिरिक्त कार्यालयहरु",
  "bottomnav.contact": "सम्पर्क गर्नुहोस",
  "bottomnav.search": "खोज्नुहोस्",
  "bottomnav.search.placeholder": "टाइप गर्नुहोस् र इन्टर गर्नुहोस्",
  "bottomnav.services": "सबै सेवाहरु",

  //   Home
  "home.highlight": "हाइलाइट",
  "home.ourfacebook": "हाम्रो फेसबुक पेज",
  "home.ourgallery": "फोटो ग्यालरी",
  "home.ourpublication": "हाम्रो प्रकासन",
  "about.title": "हुलाक सेवा को लागी परिचय",
};
